import GetFromShuffled from '../utils/GetFromShuffled';

export default GetFromShuffled([
  `compulsively readable`,
  `courageous`,
  `darkly comic`,
  `dreamlike`,
  `dystopian`,
  `edge-of-your-seat`,
  `erotic`,
  `fiercely honest`,
  `heartrending`,
  `high-voltage`,
  `hysterical`,
  `inspiring`,
  `keenly observed`,
  `lyrical`,
  `meditative`,
  `passionate`,
  `profound`,
  `psycho-sexual`,
  `razor-sharp`,
  `richly drawn`,
  `riotously funny`,
  `riveting`,
  `surreal`,
  `uncompromising`,
  `unflinching`,
  `unforgettable`,
])
