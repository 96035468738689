import GetFromShuffled from '../utils/GetFromShuffled';

export default GetFromShuffled([
  `adventure`,
  `battle`,
  `commitment`,
  `desire`,
  `devotion`,
  `dream`,
  `duty`,
  `effort`,
  `endeavor`,
  `expedition`,
  `failure`,
  `inability`,
  `journey`,
  `mission`,
  `not-so-secret desire`,
  `pains`,
  `plan`,
  `project`,
  `promise`,
  `quest`,
  `resolve`,
  `scheme`,
  `secret longing`,
  `strategy`,
  `struggle`,
  `vacation`,
  `wish`,
])
