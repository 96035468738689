import GetFromShuffled from '../utils/GetFromShuffled';

export default GetFromShuffled([
  "accept",
  "avenge",
  "avoid",
  "betray",
  "circumvent",
  "come to grips with",
  "confront",
  "contemplate",
  "defend",
  "discover",
  "embrace",
  "explore",
  "expose",
  "give up",
  "grapple with",
  "investigate",
  "navigate",
  "overcome",
  "pursue",
  "re-awaken",
  "reconcile with",
  "reconnect with",
  "transcend",
  "understand",
  "undertake",
  "withdraw from"
])
