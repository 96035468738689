import GetFromShuffled from '../utils/GetFromShuffled';

export default GetFromShuffled([
  `23andMe results`,
  `Amazon reviews`,
  `Oedipal complex`,
  `adoption`,
  `biological clock`,
  `cancer`,
  `disinheritance`,
  `eating disorder`,
  `father's murder`,
  `fear of spiders`,
  `feminism`,
  `mother's death`,
  `own birth`,
  `political apathy`,
  `privilege`,
  `religious upbringing`,
  `secret second family`,
  `sexless marriage`,
  `sexual awakening`,
  `social media addiction`,
  `spiritual evolution`,
  `toxic friendships`,
  `traumatic childhood`,
  `ugly divorce`,
  `untimely death`,
  `writer's block`,
])
